(function ($) {
  $.KyHtml = function () {
    /* Setup vars */
    var doc = $(document);

    this.init = function () {
      this.parallaxInit(doc);
    };

    this.parallaxInit = function (context) {
      if (typeof ($.fn.KyHtmlParallax) !== 'undefined') {
        var $element = $(".js-KyHtmlParallax", context);
        if ($element && $element.length > 0) {
          $element.KyHtmlParallax();
        }
      }
    };
  };

  /*@dev: For later usage if needed */
  $(function () {
    $.KyHtmlInstance = new $.KyHtml();
    //#! Initialize
    $.KyHtmlInstance.init();
  });
})(jQuery);

